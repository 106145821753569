import React, { useState, useEffect } from 'react';
//import Vitrina from './components/Vitrina';
import VitrinaDecants from './components/VitrinaDecants';
//import config from './config';  // Asegúrate de que esta importación es correcta
import './styles/general.css';
import './styles/datatable.css';
import './styles/carrito.css';
import './styles/responsive.css';
// src/index.js o src/App.js
import 'bootstrap-icons/font/bootstrap-icons.css';


function App() {
  const [isLoading, setIsLoading] = useState(true);

  // Solo establece la página al montar el componente
  useEffect(() => {
    // Simulando la finalización de la carga después de 1 segundo
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => clearTimeout(timer); // Limpiar el temporizador al desmontar el componente
  }, []);

  const renderContent = () => {
    if (isLoading) {
      return <div>Cargando...</div>;
    }
    return <VitrinaDecants />;
  };

  return (
    <div className="App">
      <main className="app-main">
        {renderContent()}
      </main>
    </div>
  );
}

export default App;
