console.log('Environment variable:', process.env.REACT_APP_API_URL);

   const config = {
     //API_URL: process.env.REACT_APP_API_URL || 'http://localhost:5000', //aca hay un problema por eso usamos en duro la url
     //API_URL: 'https://3.135.87.97:5443',
     API_URL: 'https://api.megami.cl:5443',
     //API_URL:'http://localhost:5000',
     VALIDATE_TOKEN_ENDPOINT: '/api/validate-token'
   };

   export default config;