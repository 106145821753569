import React, { useState, useEffect, useMemo, useRef } from 'react';
import config from '../config.js';
import Modal from 'react-modal';
import CartModal from './CartModal';
//botones
import CloseIcon from '../assets/boton-cerrar.png';
import AddCartIcon from '../assets/boton-agregarcarrito.png';

const urlWebassets = "https://www.casavalentine.cl/assets/"; //url base accesible desde afuera

Modal.setAppElement('#root');

const VitrinaDecants = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filters, setFilters] = useState({});
  const [fontSize, setFontSize] = useState(14);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const filterInputRefs = useRef({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [cart, setCart] = useState([]);
  const [cartModalIsOpen, setCartModalIsOpen] = useState(false); // Modal del carrito
  const [showFilters, setShowFilters] = useState(false); // Nuevo estado para controlar la visibilidad de los filtros

  useEffect(() => {
    fetch(`${config.API_URL}/api/vitrinadecants`)
      .then(response => {
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        return response.json();
      })
      .then(data => {
        setData(data);
        setFilteredData(data);
        setLoading(false);
      })
      .catch(e => {
        setError('Error al cargar los datos: ' + e.message);
        setLoading(false);
      });
  }, []);


    // Efecto para ajustar la altura dinámica
    useEffect(() => {
      const setDynamicVH = () => {
        const vh = window.innerHeight * 0.01; // Calcula el 1% de la altura de la ventana
        document.documentElement.style.setProperty('--vh', `${vh}px`); // Establece la variable CSS
      };
  
      setDynamicVH(); // Inicializa la variable
      window.addEventListener('resize', setDynamicVH); // Actualiza en cada cambio de tamaño
  
      return () => {
        window.removeEventListener('resize', setDynamicVH); // Limpieza del event listener
      };
    }, []);



  //modal de carrito
  // Añadir producto al carrito
  const addToCart = (item) => {
    const newItem = {
      id: item.id,
      marca: item.marca,
      nombre: item.nombre,
      conc: item.conc,
      imagen: item.imagen,
      titulo: item.titulo,
      valor: item.valor,
    };
    setCart(prevCart => [...prevCart, newItem]);
  };

  const openCartModal = () => setCartModalIsOpen(true);
  const closeCartModal = () => setCartModalIsOpen(false);

  // Funciones para abrir y cerrar el modal
  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  // Función para obtener la ruta del ícono de la carita según la cantidad de items en el carrito
  const getCartIcon = () => {
    const itemCount = cart.length;
    // Asegúrate de que el índice no exceda un numero
    const iconIndex = Math.min(itemCount, 18);
    return `${urlWebassets}img/carita/${iconIndex}.svg`;
  };
  
  const columnMapping = useMemo(() => {
    if (data.length === 0) return {};
    return Object.keys(data[0]).reduce((acc, key) => {
      if (key === 'tipo') acc['marca'] = key;
      else if (key !== 'proveedor') acc[key.replace(/_/g, ' ')] = key;
      return acc;
    }, {});
  }, [data]);

  const numericColumns = ['precio','valor 3ml','valor 5ml','valor 11ml'];
  const currencyColumns = ['precio','valor 3ml','valor 5ml','valor 11ml'];
  const highlightColumns = ['precio','nombre','marca'];
  const imageColumns = ['imagen', 'vit archivo imagen'];
  const idColumns = ['id', 'identificador'];
  const frascoEspecialColumns = ['valor 11ml'];

  const isNumeric = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  useEffect(() => {
    const filtered = data.filter(row =>
      Object.entries(filters).every(([key, value]) => {
        const originalKey = columnMapping[key];
        let cellValue = row[originalKey];

        if (numericColumns.includes(key.toLowerCase()) || currencyColumns.includes(key.toLowerCase())) {
          if (!isNumeric(value)) return true; // Si el filtro no es numérico, no filtramos
          cellValue = isNumeric(cellValue) ? parseFloat(cellValue) : 0;
          const filterValue = parseFloat(value);
          return cellValue === filterValue;
        }

        return cellValue.toString().toLowerCase().includes(value.toLowerCase());
      })
    );
    setFilteredData(filtered);

    // Controlar la visibilidad del div-filtros
    setShowFilters(Object.keys(filters).length > 0);
  }, [filters, data, columnMapping]);

  const handleFilterChange = (column, value) => {
    setFilters(prev => {
      const newFilters = { ...prev, [column]: value };
      // Actualizar la visibilidad del div-filtros
      setShowFilters(Object.values(newFilters).some(filter => filter !== ''));
      return newFilters;
    });
  };

  const clearFilter = (column) => {
    setFilters(prev => {
      const newFilters = { ...prev };
      delete newFilters[column];
      // Actualizar la visibilidad del div-filtros
      setShowFilters(Object.values(newFilters).some(filter => filter !== ''));
      return newFilters;
    });
    if (filterInputRefs.current[column]) {
      filterInputRefs.current[column].value = '';
    }
  };

  const clearAllFilters = () => {
    setFilters({});
    setShowFilters(false); // Ocultar el div-filtros
    Object.values(filterInputRefs.current).forEach(input => {
      if (input) input.value = '';
    });
  };

  const handleFontSizeChange = (increment) => {
    setFontSize(prevSize => Math.max(10, Math.min(20, prevSize + increment)));
  };

  //para elimianr del carrito
  const removeFromCart = (index) => {
    const newCart = cart.filter((_, i) => i !== index);
    setCart(newCart);
  };
  
  const formatCurrency = (value) => {
    if (value == null || isNaN(value)) return 'No Disponible';
    return `$${parseInt(value).toLocaleString('es-CL')}`;
  };

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    if (sortConfig.key !== null) {
      const sortedData = [...filteredData].sort((a, b) => {
        const originalKey = columnMapping[sortConfig.key];
        if (a[originalKey] < b[originalKey]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[originalKey] > b[originalKey]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      setFilteredData(sortedData);
    }
  }, [sortConfig, filteredData, columnMapping]);

  const columns = useMemo(() => {
    return Object.keys(columnMapping);
  }, [columnMapping]);

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>{error}</div>;
  if (data.length === 0) return <div>No hay datos disponibles</div>;

  return (
    <div className="data-table-container" style={{ height: 'calc(var(--vh, 1vh) * 100 - 5px)' }}>
        
        <div className="table-header">
          <div className="table-title">
            <img src={`${urlWebassets}img/casavalentine_logo.png`}  alt="CasaValentine Logo" className="logo" />
            <p className ="marca">CASAVALENTINE</p>
            <p className ="guion">-</p>
            <p className ="producto">Decants</p>
          </div>
          <div className="table-controls">
            <button className="cart-button" onClick={openCartModal}>
              <p className="cart-buttonText">Ver Carrito ({cart.length}) </p>
              <img src={getCartIcon()} alt={`Carrito ${cart.length}`} className="cart-icon-image-cambiante" />
            </button>
          </div>
        </div>


      {showFilters && ( // Mostrar el div-filtros solo si showFilters es verdadero
        <div className="div-filtros">
          
          <div className="active-filters">
            <button onClick={clearAllFilters}>Borrar filtros</button>
            <p>Filtros activos:</p>
            
            {Object.entries(filters).map(([key, value]) => (
              <span key={key} className="filter-tag">
                {key}: {value}
                <button onClick={() => clearFilter(key)}>×</button>
              </span>
            ))}
          </div>
        </div>
      )}

        <div className="font-size-controls">
          <button onClick={() => handleFontSizeChange(-1)}>-</button>
          <button onClick={() => handleFontSizeChange(1)}>+</button>
        </div>        

        <div className="table-wrapper">
          <table style={{ fontSize: `${fontSize}px` }}>
            <thead>
              <tr>
                {columns.map(column => (
                  <th 
                    key={column} 
                    className={`
                      ${highlightColumns.includes(column.toLowerCase()) ? 'highlight-column' : ''}
                      ${idColumns.includes(column.toLowerCase()) ? 'id-column' : ''}
                    `}
                  >
                    <div className="column-header">
                      <span onClick={() => requestSort(column)}>
                        {column}
                        {frascoEspecialColumns.includes(column.toLowerCase()) && (
                          <div className="floating-text">
                            Frasco especial
                          </div>
                        )}
                      </span>
                      <span className="sort-indicator">
                        {sortConfig.key === column && (
                          sortConfig.direction === 'ascending' ? '▲' : '▼'
                        )}
                      </span>
                    </div>
                    <div className="filter-container">
                      <input
                        ref={el => filterInputRefs.current[column] = el}
                        type="text"
                        className="filter-input"
                        placeholder={`Filtrar ${column}`}
                        onChange={(e) => handleFilterChange(column, e.target.value)}
                        value={filters[column] || ''}
                      />
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filteredData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {columns.map((column) => {
                    const originalColumn = columnMapping[column];
                    return (
                      <td 
                        key={column} 
                        className={`
                          ${highlightColumns.includes(column.toLowerCase()) ? 'highlight-column' : ''}
                          ${idColumns.includes(column.toLowerCase()) ? 'id-column' : ''}
                        `}
                      >
                        {imageColumns.includes(column.toLowerCase()) ? (
                                            <img
                                                src={row[originalColumn]}
                                                alt="Perfume"
                                                className="image-cell"
                                                onClick={() => openModal(row[originalColumn])}
                                                style={{ cursor: 'pointer' }}
                                            />
                    ) : column.startsWith('Valor') ? (
                      <div>
                        {row[originalColumn] != null ? (
                          <>
                            {formatCurrency(row[originalColumn])}
                            <button
                              className="add-toCart-button"
                              onClick={(event) => {
                                flyToCart(event); // Llamar a la función para animar la imagen
                                addToCart({
                                  id: row.Id,
                                  marca: row.Marca,
                                  nombre: row.Nombre,
                                  conc: row.Conc,
                                  imagen: row.Imagen,
                                  titulo: column.split(' ')[1], // Aquí se asume que el tamaño se deriva del nombre de la columna
                                  valor: row[originalColumn],
                                });
                              }}
                            >
                              <img
                                src={AddCartIcon}
                                alt="Agregar Carrito"
                                className="add-cart-icon"
                              />
                            </button>
                          </>
                        ) : (
                          <span>No disponible</span>
                        )}
                      </div>
                    ) : (
                      <span>{row[originalColumn]}</span>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Modal */}
        <Modal 
            isOpen={modalIsOpen} 
            onRequestClose={closeModal}
            className="customModalContent"
            overlayClassName="customModalOverlay"
        >
            <button className="close-button" onClick={closeModal}>
                <img src={CloseIcon} alt="Cerrar" />
            </button>
            {selectedImage && <img src={selectedImage} alt="Perfume grande" />}
        </Modal>

      {/* Modal de carrito */}
      <CartModal 
        isOpen={cartModalIsOpen} 
        closeCartModal={closeCartModal} 
        cart={cart} 
        removeFromCart={removeFromCart}  // Pasar la función aquí
      />
        <div className="miniFooter">
          <a href="https://www.instagram.com/casavalentine.cl" className="miniFooter-Links" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-instagram"></i>
            <p className="miniFooter-Texto">Instagram</p>
          </a>
          <a href="https://www.facebook.com/profile.php?id=61565349879054&sk=about" className="miniFooter-Links" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-facebook"></i>
            <p className="miniFooter-Texto">Facebook</p>
          </a>
          <a href="https://www.tiktok.com/@casavalentine.cl" className="miniFooter-Links" target="_blank" rel="noopener noreferrer">
            <i className="bi bi-tiktok"></i>
            <p className="miniFooter-Texto">TikTok</p>
          </a>
        </div>
        <div className="miniFooter-desarrollador">
          <p>
            Desarrollado y Diseñado por <a href="https://www.tiol.cl" target="_blank" rel="noopener noreferrer">Tiol</a>  2024.
          </p>
        </div>
      </div>
  );
};



// Función para animar la imagen hasta el carrito
const flyToCart = (event) => {
  const cartIcon = document.querySelector('.cart-button'); // Icono del carrito en la esquina superior derecha
  const imgToFly = event.target.closest('td').querySelector('img'); // Imagen del producto que volará

  if (imgToFly && cartIcon) {
    const imgClone = imgToFly.cloneNode(true); // Clonar la imagen para la animación
    const rect = imgToFly.getBoundingClientRect(); // Obtener la posición inicial de la imagen

    // Estilos iniciales de la imagen clonada
    imgClone.style.position = 'absolute';
    imgClone.style.top = `${rect.top}px`;
    imgClone.style.left = `${rect.left}px`;
    imgClone.style.width = `${rect.width}px`;
    imgClone.style.height = `${rect.height}px`;
    imgClone.style.zIndex = 1000;
    imgClone.style.transition = 'all 0.8s ease-in-out'; // Transición suave para la animación

    // Agregar la imagen clonada al cuerpo
    document.body.appendChild(imgClone);

    // Obtener la posición del icono del carrito para volar la imagen hacia allá
    const cartRect = cartIcon.getBoundingClientRect();

    // Ejecutar la animación con `requestAnimationFrame` para mayor fluidez
    requestAnimationFrame(() => {
      imgClone.style.transform = `translate(${cartRect.left - rect.left}px, ${cartRect.top - rect.top}px) scale(0.3)`;
    });

    // Eliminar la imagen clonada después de la animación
    setTimeout(() => {
      imgClone.remove();
    }, 800); // Tiempo igual a la duración de la animación
  }
};


export default VitrinaDecants;
